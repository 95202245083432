import React from "react";
import { styled } from "styled-components";

const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	padding: 1rem;

	@media (max-width: 480px) {
		padding: 0.5rem;
	}
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.secondaryBackground};
	padding: 20px;
	border-radius: 10px;
	max-width: 800px;
	width: 100%;
	box-sizing: border-box;

	@media (max-height: 810px) {
		border-radius: 0;
		height: 100vh;
		max-height: 100vh;
	}

	@media (max-width: 768px) {
		max-width: 600px;
	}

	@media (max-width: 480px) {
		padding: 10px;
		max-width: 400px;
	}

	@media (max-width: 375px) {
		padding: 5px;
		max-width: 320px;
	}
`;

const CloseButton = styled.button`
	background: ${({ theme }) => theme.colors.secondaryText};
	border-radius: 5px;
	color: white;
	border: none;
	font-size: 32px;
	cursor: pointer;
	margin-bottom: 2rem;
	box-shadow: 0px 0px 20px 4px #00bfa552;

	@media (max-width: 768px) {
		font-size: 28px;
		margin-bottom: 1.5rem;
	}

	@media (max-width: 480px) {
		font-size: 24px;
		margin-bottom: 1rem;
	}

	@media (max-width: 375px) {
		font-size: 20px;
		margin-bottom: 0.5rem;
	}
`;

/**
 * Modal component to display detailed information.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.show - Whether the modal is visible.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {React.ReactNode} props.children - The content to display inside the modal.
 */
const Modal = ({ show, onClose, children }) => {
	if (!show) return null;

	return (
		<ModalOverlay onClick={onClose}>
			<ModalContent onClick={e => e.stopPropagation()}>
				{children}
				<CloseButton onClick={onClose}>Close</CloseButton>
			</ModalContent>
		</ModalOverlay>
	);
};

export default Modal;
