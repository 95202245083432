import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`

    * {
        box-sizing: border-box;
    }

    body {
        font-family: 'Orbitron', sans-serif;
        background: ${theme.colors.background};
        color: ${theme.colors.text};
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
    }

    a {
        color: ${theme.colors.primaryAccentGreen};
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${theme.colors.text};
    }

    h2 {
        font-size: 24px;
        font-weight: 800;
    }


	@media (max-width: 768px) {
        h2 {
            font-size: 20px;
        }
	}

	@media (max-width: 480px) {

        h2 {
            font-size: 18px;
        }
	}

	@media (max-width: 375px) {

        h2 {
            font-size: 16px;
        }
	}

`;

export default GlobalStyle;
