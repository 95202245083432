import React from "react";
import styled, { keyframes } from "styled-components";

const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 60px;
	padding: 3rem 2rem;
	background: ${({ theme }) => theme.colors.secondaryBackground};
	width: 100%;
	max-width: 1310px;
	border-radius: 15px;
	box-shadow: 10px 12px 21.6px rgba(2, 136, 209, 0.77);
`;

const SkeletonImage = styled.div`
	width: 353px;
	height: 228px;
	border-radius: 5px;
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.primaryAccentGreen} 25%,
		${({ theme }) => theme.colors.primaryAccentBlue} 50%,
		${({ theme }) => theme.colors.primaryAccentGreen} 75%
	);
	background-size: 200px 100%;
	animation: ${loading} 1.5s infinite;
`;

const SkeletonContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const SkeletonLine = styled.div`
	width: ${({ width }) => width || "100%"};
	height: 20px;
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.primaryAccentGreen} 25%,
		${({ theme }) => theme.colors.primaryAccentBlue} 50%,
		${({ theme }) => theme.colors.primaryAccentGreen} 75%
	);
	background-size: 200px 100%;
	animation: ${loading} 1.5s infinite;
	border-radius: 5px;
`;

const Skeleton = () => (
    <SkeletonContainer>
        <SkeletonImage />
        <SkeletonContent>
            <SkeletonLine width="80%" />
            <SkeletonLine />
            <SkeletonLine width="60%" />
            <SkeletonLine />
            <SkeletonLine width="40%" />
        </SkeletonContent>
    </SkeletonContainer>
);

export default Skeleton;
