import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Image = styled.img`
	width: 80px;
	heigth: 80px;
	transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */

	&:hover {
		transform: scale(1.2);
		transform: rotate(8deg);
	}
`;

const Logo = () => {
	return (
		<Link to={"/"}>
			<Image src={process.env.PUBLIC_URL + "/LogoNoBack.webp"} alt="Logo" />
		</Link>
	);
};

export default Logo;
