import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Card from "../components/templates/Card";
import validateExperience from "../utils/validateExperience";

const ExperienceSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: calc(100vh - 91px);
	max-width: 100vw;
	overflow-x: hidden;
	padding: 2rem;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const OuterContainer = styled.div`
	width: 75%;

	@media (max-width: 768px) {
		width: 90%;
	}

	@media (max-width: 480px) {
		width: 100%;
	}
`;

const ExperiencesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	padding: 2rem;
	border: 1px solid ${({ theme }) => theme.colors.primaryAccentGreen};
	border-bottom: none;
	border-radius: 15px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	width: 100%;
	height: 100%;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const Experience = () => {
	const [experiences, setExperiences] = useState([]);

	useEffect(() => {
		const loadExperiences = async () => {
			try {
				const context = require.context("../contents/experiences", false, /\.json$/);
				const experienceData = context.keys().map(context);

				const validExperiences = experienceData.filter(validateExperience);
				setExperiences(validExperiences);
			} catch (error) {
				console.error("Error loading experiences:", error);
			}
		};

		loadExperiences();
	}, []);

	return (
		<ExperienceSection>
			<OuterContainer>
				<h2>My Experiences</h2>
				<ExperiencesContainer>
					{experiences.map((experience, index) => (
						<Card
							key={index}
							summary={experience.summary}
							imageUrl={experience.imageUrl}
							repoUrl=""
							details={[
								{ title: "Company", content: [experience.companyName] },
								{ title: "Duration", content: [`${experience.startDate} - ${experience.endDate || "Present"}`] },
								{ title: "Technologies", content: experience.technologiesUsed },
								{ title: "Title", content: [experience.jobTitle || "N/A"] }
							]}
						/>
					))}
				</ExperiencesContainer>
			</OuterContainer>
		</ExperienceSection>
	);
};

export default Experience;
