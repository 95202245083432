import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
	color: ${({ theme }) => theme.colors.primaryAccentGreen};
	font-size: 18px;
	text-align: left;
	word-wrap: break-word;
	margin-bottom: 1rem;

	@media (max-width: 768px) {
		font-size: 16px;
	}

	@media (max-width: 480px) {
		font-size: 14px;
	}

	@media (max-width: 375px) {
		font-size: 12px;
	}
`;

const Title = styled.span`
	color: #8bbaef;
	font-weight: 800;
	margin-right: 5px;
`;

const Content = styled.span`
	color: ${({ theme }) => theme.colors.primaryAccentGreen};
	font-weight: 700;
`;

const CardDetails = ({ details }) => {
	return <Container>{fillDetails(details)}</Container>;
};

/**
 * @param {Object} details - The details object containing the title and content.
 * @param {string} details.title - The title of the details.
 * @param {Array<string>} details.content - An array of strings representing the content.
 */
const fillDetails = details => {
	const { title, content } = details;

	if (!content || (content.length === 1 && content[0] === "N/A")) {
		return null;
	}

	if (content.length === 1) {
		return (
			<>
				<Title>{title}:</Title> <Content>{content[0]}</Content>
			</>
		);
	}

	return (
		<>
			<Title>{title}: </Title>
			{content.map((item, index) => (
				<Content key={index}>
					{item}
					{index < content.length - 1 && ", "}
				</Content>
			))}
		</>
	);
};

export default CardDetails;
