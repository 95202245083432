import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import { theme } from "./styles/theme";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import NavBar from "./components/NavBar";

function App() {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <NavBar />
                <Routes>
                    <Route path="/" element={<About />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/experience" element={<Experience />} />
                </Routes>
            </ThemeProvider>
        </Router>
    );
}

export default App;
