import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Line1 = styled.div`
	width: 218px;
	height: 3px;
	background: #00796b;

	@media (max-width: 480px) {
		width: 150px;
	}
`;

const Line2 = styled.div`
	width: 101px;
	height: 3px;
	background: #00796b;

	@media (max-width: 480px) {
		width: 70px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 25px;

	@media (max-width: 480px) {
		gap: 10px;
	}
`;

const Social = styled.svg`
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: rotate(8deg);
	}

	@media (max-width: 768px) {
		width: 30px;
		height: 30px;
	}

	@media (max-width: 480px) {
		width: 25px;
		height: 25px;
	}

	@media (max-width: 320px) {
		width: 20px;
		height: 20px;
	}
`;

const Socials = () => {
    return (
        <Container>
            <Line1 />
            <Link to="https://github.com/jpedro85" target="_blank">
                <Social width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.6667 34.28C10.9517 36.2083 6.19001 34.28 3.33334 28.3333"
                        stroke="#00BFA5"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.6667 36.6666V31.2633C16.6667 30.2666 16.9733 29.4 17.4667 28.6167C17.8067 28.08 17.5733 27.3167 16.9617 27.15C11.89 25.7533 8.33334 23.5117 8.33334 16.075C8.33334 14.1417 8.96668 12.325 10.08 10.7417C10.3567 10.3483 10.4967 10.1517 10.53 9.97498C10.5633 9.79498 10.505 9.56332 10.3883 9.09665C9.91668 7.20332 9.94834 5.19165 10.655 3.37998C10.655 3.37998 12.1167 2.90165 15.445 4.97998C16.205 5.45498 16.585 5.69332 16.92 5.74665C17.255 5.79998 17.7017 5.68832 18.595 5.46498C19.8723 5.14831 21.1841 4.9921 22.5 4.99998C23.8159 4.99437 25.1275 5.1511 26.405 5.46665C27.2983 5.68998 27.7467 5.79998 28.0817 5.74832C28.415 5.69498 28.795 5.45665 29.555 4.98165C32.8833 2.90332 34.345 3.38165 34.345 3.38165C35.0517 5.19332 35.0833 7.20498 34.6117 9.09832C34.495 9.56498 34.4383 9.79832 34.4717 9.97498C34.505 10.1517 34.6433 10.35 34.92 10.7433C36.0333 12.3267 36.6667 14.1433 36.6667 16.0767C36.6667 23.5133 33.11 25.755 28.0383 27.1483C27.4267 27.3167 27.1933 28.08 27.5333 28.615C28.0267 29.3983 28.3333 30.265 28.3333 31.2633V36.6666"
                        stroke="#00BFA5"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Social>
            </Link>
            <Link to="https://linkedin.com/in/jo%C3%A3o-pedro-abreu-484545299" target="_blank">
                <Social width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M33.1133 4.375H6.88672C5.5 4.375 4.375 5.5 4.375 6.88672V33.1133C4.375 34.5 5.5 35.625 6.88672 35.625H33.1133C34.5 35.625 35.625 34.5 35.625 33.1133V6.88672C35.625 5.5 34.5 4.375 33.1133 4.375ZM33.1133 33.125C15.6211 33.1211 6.875 33.1172 6.875 33.1133C6.87891 15.6211 6.88281 6.875 6.88672 6.875C24.3789 6.87891 33.125 6.88281 33.125 6.88672C33.1211 24.3789 33.1172 33.125 33.1133 33.125ZM9.00781 16.0898H13.6445V31.0039H9.00781V16.0898ZM11.3281 14.0508C12.8086 14.0508 14.0156 12.8477 14.0156 11.3633C14.0156 11.0104 13.9461 10.6609 13.8111 10.3348C13.676 10.0088 13.478 9.71249 13.2285 9.46293C12.9789 9.21337 12.6826 9.01541 12.3566 8.88035C12.0305 8.7453 11.6811 8.67578 11.3281 8.67578C10.9752 8.67578 10.6257 8.7453 10.2997 8.88035C9.9736 9.01541 9.67733 9.21337 9.42778 9.46293C9.17822 9.71249 8.98026 10.0088 8.8452 10.3348C8.71014 10.6609 8.64062 11.0104 8.64062 11.3633C8.63672 12.8477 9.83984 14.0508 11.3281 14.0508ZM21.1836 23.625C21.1836 21.6797 21.5547 19.7969 23.9648 19.7969C26.3398 19.7969 26.375 22.0195 26.375 23.75V31.0039H31.0078V22.8242C31.0078 18.8086 30.1406 15.7188 25.4492 15.7188C23.1953 15.7188 21.6836 16.957 21.0625 18.1289H21V16.0898H16.5508V31.0039H21.1836V23.625Z"
                        fill="#00BFA5"
                    />
                </Social>
            </Link>
            <Line2 />
        </Container>
    );
};

export default Socials;
