import React from "react";
import styled from "styled-components";
import HeroImage from "../HeroImage";

const HeroSection = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 1024px) {
		flex-direction: column;
		padding: 20px;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		flex-direction: row;
		padding: 10px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		padding: 20px;
	}

	@media (max-width: 768px) and (orientation: landscape) {
		flex-direction: row;
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}

	@media (max-width: 480px) and (orientation: landscape) {
		flex-direction: row;
		padding: 5px;
	}

	@media (max-width: 375px) {
		padding: 10px;
	}

	@media (max-width: 375px) and (orientation: landscape) {
		flex-direction: row;
		padding: 5px;
	}
`;

const HeroContent = styled.div`
	padding: 60px 20px;
	margin-bottom: 20px;
	max-width: 900px;

	@media (max-width: 1024px) {
		padding: 40px 20px;
		margin-bottom: 10px;
		max-width: 100%;
		text-align: center;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		padding: 20px 10px;
		text-align: left;
	}

	@media (max-width: 768px) {
		padding: 40px 20px;
		margin-bottom: 10px;
		max-width: 100%;
		text-align: center;
	}

	@media (max-width: 768px) and (orientation: landscape) {
		padding: 20px 10px;
		text-align: left;
	}

	@media (max-width: 480px) {
		padding: 20px 10px;
	}

	@media (max-width: 480px) and (orientation: landscape) {
		padding: 10px 5px;
		text-align: left;
	}

	@media (max-width: 375px) {
		padding: 15px 10px;
	}

	@media (max-width: 375px) and (orientation: landscape) {
		padding: 10px 5px;
		text-align: left;
	}
`;

const HighlightText = styled.span`
	color: ${({ theme }) => theme.colors.secondaryAccentBlue};
`;

const Hack = styled.span`
	font-family: "Oxanium", sans-serif;
	font-weight: 700;
	font-size: 27px;

	@media (max-width: 1024px) {
		font-size: 24px;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		font-size: 22px;
	}

	@media (max-width: 768px) {
		font-size: 23px;
	}

	@media (max-width: 768px) and (orientation: landscape) {
		font-size: 20px;
	}

	@media (max-width: 480px) {
		font-size: 20px;
	}

	@media (max-width: 480px) and (orientation: landscape) {
		font-size: 18px;
	}

	@media (max-width: 375px) {
		font-size: 18px;
	}

	@media (max-width: 375px) and (orientation: landscape) {
		font-size: 16px;
	}
`;

const Summary = styled.p`
	font-weight: 800;
	font-size: 24px;
	line-height: 40px;
	text-align: justify;

	@media (max-width: 1024px) {
		font-size: 22px;
		line-height: 32px;
		text-align: center;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		font-size: 20px;
		line-height: 28px;
		text-align: left;
	}

	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}

	@media (max-width: 768px) and (orientation: landscape) {
		font-size: 18px;
		line-height: 28px;
		text-align: left;
	}

	@media (max-width: 480px) {
		font-size: 14px;
		line-height: 20px;
	}

	@media (max-width: 480px) and (orientation: landscape) {
		font-size: 12px;
		line-height: 18px;
		text-align: left;
	}

	@media (max-width: 375px) {
		font-size: 10px;
		line-height: 16px;
	}

	@media (max-width: 375px) and (orientation: landscape) {
		font-size: 8px;
		line-height: 14px;
		text-align: left;
	}
`;

const Hero = () => (
	<HeroSection>
		<HeroContent>
			<h2>
				Hi, I'm{" "}
				<HighlightText>
					Jo<Hack>ã</Hack>o Pedro
				</HighlightText>
			</h2>
			<Summary>
				A passionate <HighlightText>software developer</HighlightText> driven by curiosity for all things programming, learning, and innovation. My
				journey through technology is fueled by a deep desire to understand not just the <HighlightText>"what"</HighlightText> but the{" "}
				<HighlightText>"how" </HighlightText>
				and the <HighlightText>"why"</HighlightText> behind the tools that define our digital era.
			</Summary>
		</HeroContent>
		<HeroImage />
	</HeroSection>
);

export default Hero;
