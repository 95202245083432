export const theme = {
    colors: {
        primaryAccentBlue: "#00796b",
        primaryAccentGreen: "#00bfa5",
        secondaryAccentBlue: "#0288D1",
        secondaryAccentGreen: "#66bb6a",
        background: "#0F1627",
        secondaryBackground: "#0A4F47",
        text: "#00796b",
        secondaryText: "#102D77",
    },
};
