import React from "react";
import { styled } from "styled-components";

const Button = styled.a`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0;
	width: 103px;
	height: 32px;
	margin: 10px 0;
	font-size: 16px;
	border: 1px solid ${({ theme }) => theme.colors.primaryAccentGreen};
	border-radius: 5px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primaryAccentBlue};
	position: relative;
	overflow: hidden;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.primaryAccentGreen};
		z-index: -1;
		transition: width 0.3s ease-in-out;
	}

	&:hover::before {
		width: 100%;
	}

	&:hover {
		color: ${({ theme }) => theme.colors.primaryBackground};
	}
`;

const PDFButton = ({ url, label }) => {
    return (
        <Button href={url} target="_blank" rel="noopener noreferrer">
            {label}
        </Button>
    );
};

export default PDFButton;
