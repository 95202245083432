import Ajv from "ajv";
import schema from "../schemas/projectsSchema.json";

const ajv = new Ajv();

const validateProjects = data => {
	const validate = ajv.compile(schema);
	const valid = validate(data);
	if (!valid) {
		console.error("Invalid data:", validate.errors);
		return false;
	}
	return true;
};

export default validateProjects;
