import React from "react";
import styled from "styled-components";
import Hero from "../components/sections/Hero";
import Socials from "../components/Socials";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	min-height: calc(100vh - 92px);
	padding: 3rem 5rem;

	@media (max-width: 768px) {
		padding: 2rem;
	}

	@media (max-width: 480px) {
		padding: 1rem;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const About = () => (
	<Container>
		<ContentWrapper>
			<Hero />
		</ContentWrapper>
		<Socials />
	</Container>
);

export default About;
