import React, { useState } from "react";
import styled from "styled-components";
import CardDetails from "../CardDetails";
import Modal from "../Modal";
import "./Zoom.css";
import { Link } from "react-router-dom";
import Skeleton from "../Skeleton";

const Container = styled.div`
	background: ${({ theme }) => theme.colors.secondaryBackground};
	max-width: 1310px;
	box-shadow: 10px 12px 21.6px rgba(2, 136, 209, 0.77);
	display: flex;
	flex-direction: row;
	gap: 60px;
	border-radius: 15px;
	padding: 3rem 2rem;
	cursor: pointer;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 20px;
		padding: 1rem;
	}

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 1rem;
	}

	@media (max-width: 375px) {
		box-shadow: 4px 5px 20px rgba(2, 136, 209, 0.77);
		padding: 0.5rem;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Details = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, auto);
	column-gap: 59px;
	row-gap: 10px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

const Image = styled.img`
	max-width: 353px;
	max-height: 228px;

	@media (max-width: 1024px), (max-width: 768px) {
		max-width: 100%;
		border-radius: 10px;
		height: auto;
	}
`;

const ExpandedImage = styled.img`
	width: auto;
	height: auto;
	max-height: 300px;
	max-width: 100%;
	margin-bottom: 20px;

	@media (max-width: 1024px), (max-width: 768px), (max-height: 810px) {
		border-radius: 10px;
		max-height: 200px;
		max-width: 90%;
	}
`;

const Summary = styled.span`
	color: ${({ theme }) => theme.colors.secondaryAccentBlue};
	font-weight: 800;
	font-size: 20px;
	text-align: justify;
	margin-bottom: 20px;

	@media (max-width: 768px) {
		font-size: 18px;
	}

	@media (max-width: 480px), (max-width: 375px) {
		font-size: 14px;
	}

`;

const Header = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const Title = styled.h2`
	color: #8bbaef;
`;

const ModalContent = styled.div`
	color: ${({ theme }) => theme.colors.secondaryAccentBlue};
	font-weight: 800;
	font-size: 18px;
	text-align: justify;
	margin-bottom: 2rem;
	max-height: 585px;
	width: 100%;

	@media (max-width: 768px) {
		font-size: 16px;
	}

	@media (max-width: 480px) {
		font-size: 14px;
	}

	@media (max-width: 375px) {
		font-size: 12px;
	}
`;

const Social = styled.svg`
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: rotate(8deg);
	}
`;

const FullScreenModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.secondaryBackground};
	z-index: 1000;
	overflow-y: auto;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	@media (max-width: 768px) {
		padding: 1rem;
	}

	@media (max-width: 480px) {
		padding: 0.5rem;
	}

	@media (max-width: 375px) {
		padding: 0.5rem;
	}
`;

const CloseButton = styled.button`
	background: ${({ theme }) => theme.colors.secondaryText};
	border-radius: 5px;
	color: white;
	border: none;
	font-size: 32px;
	cursor: pointer;
	margin-bottom: 2rem;
	box-shadow: 0px 0px 20px 4px #00bfa552;

	@media (max-width: 768px) {
		font-size: 28px;
		margin-bottom: 1.5rem;
	}

	@media (max-width: 480px) {
		font-size: 24px;
		margin-bottom: 1rem;
	}

	@media (max-width: 375px) {
		font-size: 20px;
		margin-bottom: 0.5rem;
	}
`;

/**
 * Card component to display a summary, an image, and detailed information.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.summary - A brief summary of the card content.
 * @param {string} props.imageUrl - The URL/path to the image.
 * @param {Array<{title: string, content: Array<string>}>} props.details - An array of objects representing the details of the card. Each object contains a title and content.
 */
const Card = ({ summary, imageUrl, details, repoUrl }) => {
    const [showModal, setShowModal] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const handleCardClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsZoomed(false);
    };

    const toggleZoom = () => {
        if (window.innerWidth > 768) {
            setIsZoomed(!isZoomed);
        }
    };

    return (
        <>
            {!loaded && <Skeleton />}
            <Container onClick={handleCardClick} style={{ display: loaded ? "flex" : "none" }}>
                <Image src={process.env.PUBLIC_URL + imageUrl} onLoad={() => setLoaded(true)} loaded={loaded.toString()} />
                <Content>
                    <Summary>{truncateText(summary, 500)}</Summary>
                    <Details>
                        {details.map((detail, index) => (
                            <CardDetails key={index} details={detail} />
                        ))}
                    </Details>
                </Content>
            </Container>
            {showModal && window.innerWidth <= 1024 && (
                <>
                    {/* Full-Screen Modal for Mobile */}
                    <FullScreenModal>
                        <Header>
                            <Title>Detailed Information</Title>
                            {repoUrl && (
                                <Link to={repoUrl}>
                                    <Social xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 64 64">
                                        <path d="M32 6C17.641 6 6 17.641 6 32c0 12.277 8.512 22.56 19.955 25.286-.592-.141-1.179-.299-1.755-.479V50.85c0 0-.975.325-2.275.325-3.637 0-5.148-3.245-5.525-4.875-.229-.993-.827-1.934-1.469-2.509-.767-.684-1.126-.686-1.131-.92-.01-.491.658-.471.975-.471 1.625 0 2.857 1.729 3.429 2.623 1.417 2.207 2.938 2.577 3.721 2.577.975 0 1.817-.146 2.397-.426.268-1.888 1.108-3.57 2.478-4.774-6.097-1.219-10.4-4.716-10.4-10.4 0-2.928 1.175-5.619 3.133-7.792C19.333 23.641 19 22.494 19 20.625c0-1.235.086-2.751.65-4.225 0 0 3.708.026 7.205 3.338C28.469 19.268 30.196 19 32 19s3.531.268 5.145.738c3.497-3.312 7.205-3.338 7.205-3.338.567 1.474.65 2.99.65 4.225 0 2.015-.268 3.19-.432 3.697C46.466 26.475 47.6 29.124 47.6 32c0 5.684-4.303 9.181-10.4 10.4 1.628 1.43 2.6 3.513 2.6 5.85v8.557c-.576.181-1.162.338-1.755.479C49.488 54.56 58 44.277 58 32 58 17.641 46.359 6 32 6zM33.813 57.93C33.214 57.972 32.61 58 32 58 32.61 58 33.213 57.971 33.813 57.93zM37.786 57.346c-1.164.265-2.357.451-3.575.554C35.429 57.797 36.622 57.61 37.786 57.346zM32 58c-.61 0-1.214-.028-1.813-.07C30.787 57.971 31.39 58 32 58zM29.788 57.9c-1.217-.103-2.411-.289-3.574-.554C27.378 57.61 28.571 57.797 29.788 57.9z"></path>
                                    </Social>
                                </Link>
                            )}
                        </Header>
                        {isZoomed ? (
                            <ExpandedImage
                                src={process.env.PUBLIC_URL + imageUrl}
                                className={`zoom-image ${isZoomed ? "zoomed" : ""}`}
                                onClick={toggleZoom}
                                onLoad={() => setLoaded(true)}
                                loaded={loaded.toString()}
                                style={{
                                    position: "relative",
                                    transform: `scale(2)`
                                }}
                            />
                        ) : (
                            <ExpandedImage
                                src={process.env.PUBLIC_URL + imageUrl}
                                className={`zoom-image`}
                                onClick={toggleZoom}
                                onLoad={() => setLoaded(true)}
                                loaded={loaded.toString()}
                                style={{
                                    transform: `scale(1)`
                                }}
                            />
                        )}
                        <ModalContent>{summary}</ModalContent>
                        <Details>
                            {details.map((detail, index) => (
                                <CardDetails key={index} details={detail} />
                            ))}
                        </Details>
                        <CloseButton onClick={handleCloseModal}>Close</CloseButton>
                    </FullScreenModal>
                </>
            )}
            {/* Normal Modal for Desktop */}
            {showModal && window.innerWidth > 1024 && (
                <Modal show={showModal} onClose={handleCloseModal}>
                    <Header>
                        <Title>Detailed Information</Title>
                        {repoUrl && (
                            <Link to={repoUrl}>
                                <Social xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 64 64">
                                    <path d="M32 6C17.641 6 6 17.641 6 32c0 12.277 8.512 22.56 19.955 25.286-.592-.141-1.179-.299-1.755-.479V50.85c0 0-.975.325-2.275.325-3.637 0-5.148-3.245-5.525-4.875-.229-.993-.827-1.934-1.469-2.509-.767-.684-1.126-.686-1.131-.92-.01-.491.658-.471.975-.471 1.625 0 2.857 1.729 3.429 2.623 1.417 2.207 2.938 2.577 3.721 2.577.975 0 1.817-.146 2.397-.426.268-1.888 1.108-3.57 2.478-4.774-6.097-1.219-10.4-4.716-10.4-10.4 0-2.928 1.175-5.619 3.133-7.792C19.333 23.641 19 22.494 19 20.625c0-1.235.086-2.751.65-4.225 0 0 3.708.026 7.205 3.338C28.469 19.268 30.196 19 32 19s3.531.268 5.145.738c3.497-3.312 7.205-3.338 7.205-3.338.567 1.474.65 2.99.65 4.225 0 2.015-.268 3.19-.432 3.697C46.466 26.475 47.6 29.124 47.6 32c0 5.684-4.303 9.181-10.4 10.4 1.628 1.43 2.6 3.513 2.6 5.85v8.557c-.576.181-1.162.338-1.755.479C49.488 54.56 58 44.277 58 32 58 17.641 46.359 6 32 6zM33.813 57.93C33.214 57.972 32.61 58 32 58 32.61 58 33.213 57.971 33.813 57.93zM37.786 57.346c-1.164.265-2.357.451-3.575.554C35.429 57.797 36.622 57.61 37.786 57.346zM32 58c-.61 0-1.214-.028-1.813-.07C30.787 57.971 31.39 58 32 58zM29.788 57.9c-1.217-.103-2.411-.289-3.574-.554C27.378 57.61 28.571 57.797 29.788 57.9z"></path>
                                </Social>
                            </Link>
                        )}
                    </Header>
                    {isZoomed ? (
                        <ExpandedImage
                            src={process.env.PUBLIC_URL + imageUrl}
                            className={`zoom-image ${isZoomed ? "zoomed" : ""}`}
                            onClick={toggleZoom}
                            onLoad={() => setLoaded(true)}
                            loaded={loaded.toString()}
                            style={{
                                position: "relative",
                                transform: `scale(2)`
                            }}
                        />
                    ) : (
                        <ExpandedImage
                            src={process.env.PUBLIC_URL + imageUrl}
                            className={`zoom-image`}
                            onClick={toggleZoom}
                            onLoad={() => setLoaded(true)}
                            loaded={loaded.toString()}
                            style={{
                                transform: `scale(1)`
                            }}
                        />
                    )}
                    <ModalContent>{summary}</ModalContent>
                    <Details>
                        {details.map((detail, index) => (
                            <CardDetails key={index} details={detail} />
                        ))}
                    </Details>
                </Modal>
            )}
        </>
    );
};

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + "...";
};

export default Card;
