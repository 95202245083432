import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PDFButton from "./templates/PDFButton";
import Logo from "./Logo";

const Container = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.primaryAccentBlue};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-height: 91px;
	width: 100vw;
	padding: 2rem 5rem;

	@media (max-width: 768px) {
		padding: 1rem 2rem;
	}
`;

const Links = styled.ol`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	margin: 0;
	counter-reset: item;
	list-style: none;
	font-size: 16px;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0;
		right: 0;
		height: 100vh;
		width: 200px;
		background-color: #0c121f;
		transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
		${({ transitionEnabled }) => (transitionEnabled ? "transition: transform 0.3s ease-in-out;" : "")};
		padding-top: 5rem;
		z-index: 5;
	}
`;

const LinkItem = styled.li`
	color: ${({ theme }) => theme.colors.primaryAccentGreen};
	padding: 10px;
	display: flex;
	align-items: center;
	counter-increment: item;
	position: relative;

	&::before {
		content: counter(item) ". ";
		margin-right: 5px;
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		height: 2px;
		width: 0;
		background-color: ${({ theme }) => theme.colors.primaryAccentGreen};
		transition: width 0.3s ease-in-out;
	}

	&:hover::after {
		width: 100%;
	}

	@media (max-width: 768px) {
		padding: 20px 10px;
		width: 100%;
	}
`;

const PdfLinkItem = styled(LinkItem)`
	counter-increment: none;

	&::before {
		content: "";
	}

	&:hover::after {
		width: 0;
	}
`;

const ItemsLeft = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

const ItemsRight = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: auto;
	gap: 1rem;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
`;

const Hamburger = styled.div`
	display: none;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.25rem;
		background: ${({ theme }) => theme.colors.primaryAccentGreen};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		&:first-child {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}

		&:nth-child(2) {
			opacity: ${({ open }) => (open ? "0" : "1")};
			transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
		}

		&:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
	}

	@media (max-width: 768px) {
		display: flex;
	}
`;

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [transitionEnabled, setTransitionEnabled] = useState(false);

    const handleHamburgerClick = () => {
        setTransitionEnabled(true);
        setOpen(!open);
    };

    useEffect(() => {
        const handleResize = () => {
            setTransitionEnabled(false);
            setOpen(false);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container>
            <ItemsLeft>
                <Logo />
            </ItemsLeft>
            <Hamburger open={open} onClick={handleHamburgerClick}>
                <div />
                <div />
                <div />
            </Hamburger>
            <ItemsRight>
                <Links open={open} transitionEnabled={transitionEnabled}>
                    <LinkItem>
                        <Link to="/" onClick={() => setOpen(false)}>
                            About Me
                        </Link>
                    </LinkItem>
                    <LinkItem>
                        <Link to="/projects" onClick={() => setOpen(false)}>
                            Projects
                        </Link>
                    </LinkItem>
                    <LinkItem>
                        <Link to="/experience" onClick={() => setOpen(false)}>
                            Experience
                        </Link>
                    </LinkItem>
                    <PdfLinkItem>
                        <PDFButton url={process.env.PUBLIC_URL + "/Resume.pdf"} label="Resume" />
                    </PdfLinkItem>
                </Links>
            </ItemsRight>
        </Container>
    );
};

export default Navbar;
