import React from "react";
import styled from "styled-components";

const Profile = styled.img`
	width: 658px;
	height: 662px;

	@media (max-width: 1024px) {
		width: 100%;
		height: auto;
		max-width: 500px;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		max-width: 450px;
	}

	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		max-width: 300px;
	}

	@media (max-width: 768px) and (orientation: landscape) {
		max-width: 250px;
	}

	@media (max-width: 480px) {
		max-width: 200px;
	}

	@media (max-width: 480px) and (orientation: landscape) {
		max-width: 180px;
	}

	@media (max-width: 375px) {
		max-width: 150px;
	}

	@media (max-width: 375px) and (orientation: landscape) {
		max-width: 120px;
	}
`;

const HeroImage = () => {
	return <Profile src={process.env.PUBLIC_URL + "/ProfileImage.png"} />;
};

export default HeroImage;
